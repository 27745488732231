import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/de'
import ProjectCard from '../components/ProjectCard'
import SubTitle from '../components/Titles/SubTitle'

const lang = 'de'

export default class ProjectsPage extends React.Component {
  render() {
    const { data, location } = this.props
    const projects = data.projects.edges
    projects.sort((a, b) =>
      Number(b.node.year) > Number(a.node.year) ? 1 : -1
    )
    const studentProjects = projects.filter(
      (p) => p.node.type === 'student' && p.node.publish
    )
    const fundedProjects = projects.filter(
      (p) => p.node.type === 'funded' && p.node.publish
    )

    return (
      <Layout location={location}>
        <SubTitle>Geförderte Projekte.</SubTitle>
        <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {fundedProjects.map((p) => (
            <ProjectCard project={p.node} key={p.node.title} lang={lang} />
          ))}
        </section>

        <SubTitle>Studentenprojekte.</SubTitle>
        <section className="grid grid-cols-1 mb-10 sm:grid-cols-2 md:grid-cols-3">
          {studentProjects.map((p) => (
            <ProjectCard project={p.node} key={p.node.title} lang={lang} />
          ))}
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    projects: allDirectusProject {
      edges {
        node {
          id
          year
          type
          title
          slug
          publish
          translations {
            language
            additional_info
            description
            title
          }
          partners {
            name
          }
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
